import styled from 'styled-components';
import { Span } from 'components/base-ui/typo';
import IconButton from 'components/base-ui/icon-button';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { DivProps } from './item';
import { fade } from '../../../styles';

interface OuterProps {
  src: string;
}

const maxBannerHeight = '784px';

const Outer = styled.div<OuterProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: inherit;
  background-size: cover;
  background-image: url(${(props) => props.src});
  background-position: top center;
  transition: background-image 0.5s linear;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    background-position: center;
  }
`;

const offsetX = '-50px';
const bgWidth = '792px';
const bgGradient = COLORS.secondary;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: calc(${bgWidth} - 280px);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: ${bgWidth};
  }

  &:after {
    content: '';
    top: 0;
    right: 0;
    position: absolute;
    height: inherit;
    width: ${bgWidth};
    mix-blend-mode: multiply;
    background-size: auto 568px;
    background-repeat: no-repeat;
    background-position: top 0 left 0;
    background: ${bgGradient};

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      transform: skewX(-6deg);
      right: unset;
      background-size: auto 896px;
      left: calc(${offsetX} - 280px);
    }

    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      left: ${offsetX};
    }
  }
`;

const IndexCircle = styled.div`
  top: 33%;
  left: 100%;
  width: 143px;
  height: 143px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    right: -16px;
    left: unset;
  }

  &:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: multiply;
    background-color: ${bgGradient};
  }
`;

const Index = styled(Span)`
  font-size: 44px;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
  isolation: isolate;
  z-index: 50;
`;

const Slides = styled.div`
  width: 100%;
  height: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Navigation = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 24px;
  left: 16px;
  bottom: 40px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    left: 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    left: 136px;
  }
`;

interface NavigationItemProps {
  active?: boolean;
}

const NavigationItem = styled.button<NavigationItemProps>`
  width: ${(props) => (props.active ? 18 : 10)}px;
  height: ${(props) => (props.active ? 18 : 10)}px;
  padding: 0;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  transition: all 0.2s ease-out;
  flex-shrink: 0;
  background-color: ${COLORS.white};
`;

const NavigationItemWrapper = styled.div`
  width: 24px;
  height: 24px;
  padding: 0;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  -webkit-tap-highlight-color: transparent;
`;

const Next = styled.div`
  position: absolute;
  top: 0;
  right: -64px;
  z-index: 10;
  width: 136px;
  height: 568px;
  mix-blend-mode: multiply;
  background: ${bgGradient};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    height: ${maxBannerHeight};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    right: 0;
  }
`;

const NextButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  -webkit-tap-highlight-color: transparent;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    right: 56px;
  }
`;

export const SlideWrapper = styled.div<DivProps>`
  z-index: 5;
  height: inherit;
  animation: ${fade} 0.2s;
  padding: 100px 0 75px 16px;
  max-width: calc(240px + 63.5px + 16px);
  grid-template-rows: auto 1fr auto;
  display: ${(props) => (props.active ? 'grid' : 'none')};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 176px 0 80px 56px;
    max-width: calc(384px + 71.5px + 56px);
    grid-template-rows: repeat(3, min-content);
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 248px 0 80px 136px;
    max-width: calc(545px + 112px + 136px);
  }
`;

export const Styled = {
  Next,
  NextButton,
  Navigation,
  NavigationItem,
  Outer,
  Inner,
  IndexCircle,
  Slides,
  Index,
  SlideWrapper,
  NavigationItemWrapper,
};
