import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import { H5 } from 'components/base-ui/typo';
import IconButton from 'components/base-ui/icon-button';
import AnimateHeight from 'react-animate-height';

export interface DivProps {
  bordered?: boolean;
}

interface ToggleButtonProps {
  active?: boolean;
}

export const border = css`
  &:after {
    content: '';
    bottom: 0;
    left: 16px;
    right: 16px;
    height: 1px;
    position: absolute;
    background: ${COLORS.gray5};

    @media screen and (min-width: ${BREAKPOINTS.tablet}) {
      left: 88px;
      right: 88px;
    }

    @media screen and (min-width: ${BREAKPOINTS.laptop}) {
      left: 32px;
      right: 32px;
    }
  }
`;

export const borderedStyles = css`
  ${border};
`;

export const Div = styled.div<DivProps>`
  position: relative;

  ${(props) => props.bordered && borderedStyles};

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: grid;
    column-gap: 16px;
    grid-template-columns: 1fr auto;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    column-gap: 128px;
  }
`;

export const Body = styled.div`
  padding: 0 16px 36px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 28px 0 36px 88px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 28px 0 36px 32px;
  }
`;

export const Sidebar = styled.aside`
  @media screen and (max-width: calc(${BREAKPOINTS.tablet} - 1px)) {
    top: 28px;
    right: 16px;
    position: absolute;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 88px 0 32px;
    margin: 24px 0 48px 0;
    border-left: 1px solid ${COLORS.gray5};
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 32px;
  }
`;

export const Heading = styled(H5)`
  font-weight: 400;
  color: ${COLORS.secondary};
  transition: margin 0.5s ease-out;

  @media screen and (max-width: calc(${BREAKPOINTS.tablet} - 1px)) {
    max-width: calc(100% - 42px - 16px);
  }
`;

export const ChildrenWrapper = styled.div`
  overflow: hidden;
  color: ${COLORS.gray2};
`;

export const activeStyles = css`
  transform: rotate(-90deg);
`;

export const ToggleButton = styled(IconButton)<ToggleButtonProps>`
  transform: rotate(90deg);
  transition: transform 0.5s ease-out;

  ${(props) => props.active && activeStyles}
`;

export const AnimHeight = styled(AnimateHeight)<{ hasTitle?: boolean }>`
  margin-top: ${({ hasTitle }) => (hasTitle ? '20px' : '0')};
  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
  &.rah-animating--down,
  &.rah-static--height-auto {
    -webkit-mask-image: linear-gradient(180deg, #000 100%, transparent);
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    margin-top: 20px;
  }
`;
