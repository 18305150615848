import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { NewsPage } from 'services';
import { useSearchStore } from 'store';
import Table from 'components/base-ui/table';
import NewsCards from 'components/news/list/cards';
import { SearchSection } from '../index';

export const NewsSearchTable: React.FC = observer(() => {
  const store = useSearchStore();
  const { searchValue } = useParams<{ searchValue: string }>();

  return store.fieldData('newsPages').length ? (
    <SearchSection compact heading="Новости" noFirstOfTypeOffset>
      <Table<NewsPage, never>
        count={store.fieldCount('newsPages')}
        currentPage={store.fieldPage('newsPages')}
        cards={<NewsCards items={store.fieldData('newsPages') as NewsPage[]} />}
        onPagination={(page) => store.onPaginate(searchValue, 'newsPages', 'news_pages', page)}
      />
    </SearchSection>
  ) : null;
});
