import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 308px;
    padding: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 302px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 346px;
  }
`;

export const StImageWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: auto;
  margin-bottom: 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 308px;
    height: 308px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 302px;
    height: 302px;
  }

  @media screen and (min-width: ${BREAKPOINTS.wideDesktop}) {
    width: 346px;
    height: 346px;
  }
`;

export const StImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const StTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: ${COLORS.gray3};
`;

export const StText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${COLORS.gray3};
  h5 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 4px 0 8px 0;
  }
`;
